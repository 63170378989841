import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { environment } from "environments/environment";
import { NotificationService } from "app/_shared/services/notification.service";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private zone: NgZone,
  ) {}

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      this.zone.run(() => {
        if (error?.status !== 401) {
          this.notificationService.error(error?.error?.message ?? "An error has occurred.");
        }
      });
    }
    if (!environment.production) {
      console.error(error);
    }
  }
}
