import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-enterprise";
import { Component } from "@angular/core";
import { isEmpty } from "lodash";

interface GridAction {
  name: string;
  requireConfirm: boolean;
  isDanger: boolean;
  isDisabled: boolean;
  cond: {
    key: string;
    value: boolean | string;
  };
}

@Component({
  standalone: false,
  selector: "ag-grid-custom-cell-action",
  template: `
    @if (isDeleted) {
      <nz-space [nzSplit]="spaceSplit">
        <a
          nz-typography
          nz-popconfirm
          [nzOkDanger]="true"
          nzPopconfirmTitle="Are you sure ?"
          (nzOnConfirm)="applyAction('restore')">
          <span class="hover:underline text-red-500">
            {{ "restore" | titlecase }}
          </span>
        </a>
      </nz-space>
    } @else {
      <nz-space [nzSplit]="spaceSplit">
        @for (action of actions; track $index; let last = $last) {
          <a
            [nzDisabled]="(action.name === 'delete' && isUndeletable) || (action.name === 'edit' && isUneditable)"
            nz-typography
            nz-popconfirm
            [nzCondition]="!action.requireConfirm"
            [nzOkDanger]="action.isDanger"
            nzPopconfirmTitle="Are you sure ?"
            (nzOnConfirm)="applyAction(action.name)">
            <span
              [ngClass]="{
                'hover:underline': !(
                  (action.name === 'delete' && isUndeletable) ||
                  (action.name === 'edit' && isUneditable)
                ),
                '!text-gray': (action.name === 'delete' && isUndeletable) || (action.name === 'edit' && isUneditable),
                'text-red-500': action.isDanger,
                'text-blue-500': !action.isDanger,
              }">
              {{ action.name | titlecase }}
            </span>
            @if (!last) {
              <nz-divider nzType="vertical"></nz-divider>
            }
          </a>
        }
      </nz-space>
    }
    <ng-template #spaceSplit>
      <nz-divider nzType="vertical"></nz-divider>
    </ng-template>
  `,
})
export class AgGridCustomCellActionsComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  actions: GridAction[] = [];
  isDeleted: boolean;
  isUneditable: boolean;
  isUndeletable: boolean;

  constructor() {}

  ngOnInit(): void {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams & { actions: GridAction[] }): void {
    this.params = params;
    this.actions = params.actions?.filter((action) => {
      if (!isEmpty(action.cond)) {
        return params.data[action.cond.key] === action.cond.value;
      }
      return true;
    });
    this.isDeleted = params.data?.isDeleted;
    this.isUneditable = params.data?.isUneditable;
    this.isUndeletable = params.data?.isUndeletable;
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams): boolean {
    return true;
  }

  async applyAction(name: string): Promise<void> {
    this.params.context.component[name](this.params.data);
  }
}
